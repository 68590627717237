import React from 'react'
import NavBar from './NavBar'

function NotFound() {
  return (
    <div>
      <NavBar />
      NotFound
    </div>
  )
}

export default NotFound